import { createSlice } from '@reduxjs/toolkit';
import { getAllChats, getAllNotificationforAdmin, getAllNotificationforUser, getChatHeader, getChatHistory } from '../model/configapi';

export const userSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoggingModel: false,
    wishListCount: false,
    messageCount: 0,
    isMobileSidebarOpen: false,
    searchKey: '',
    productParams: 0,
    categoryId: 0,
    snackbar: {
      open: null,
      message: null,
      severity: null,
    },
    stateId: 0,
    chatState: {
      senderId: null,
      recipientId: null,
      productId: null,
    },
    isAdminLogin: false,
    conversationList: [],
    currentMessages: [],
    currentConversation: null,
    productDelete: null,
    AdminUser: null,
    messageCountList: [],
    lastPage: null,
    chatHeaderData: {},
    userNotifications: [],
    adminNotifications: [],
    isChatProductActive: true,
    pageReload:false,
  },
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    open: (state) => {
      state.isLoggingModel = true;
    },
    close: (state) => {
      state.isLoggingModel = false;
    },
    openSidebar: (state) => {
      state.isMobileSidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.isMobileSidebarOpen = false;
    },
    add: (state) => {
      state.wishListCount = true;
    },
    remove: (state) => {
      state.wishListCount = false;
    },
    search: (state, action) => {
      state.searchKey = action.payload;
    },
    prodParams: (state, action) => {
      state.productParams = action.payload;
    },
    openSnackBar: (state, action) => {
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },
    closeSnackBar: (state) => {
      state.snackbar.open = false;
      state.snackbar.severity = null;
      state.snackbar.message = null;
    },
    updateStateId: (state, action) => {
      state.stateId = action.payload.stateId;
    },
    updateChatState: (state, action) => {
      state.chatState.senderId = action.payload.senderId;
      state.chatState.recipientId = action.payload.recipientId;
      state.chatState.productId = action.payload.productId;
    },
    updateAdminLogin: (state, action) => {
      state.isAdminLogin = action.payload.isAdminLogin;
    },
    fetchDirectConversations: (state, action) => {
      state.conversationList = action.payload.conversationList;
    },
    fetchCurrentMessages: (state, action) => {
      state.currentMessages = action.payload.messages;
    },
    addDirectMessage(state, action) {
      state.currentMessages.push(action.payload);
    },
    setCurrentConversation(state, action) {
      state.currentConversation = action.payload;
    },
    updateProductDelete(state) {
      state.productDelete = true;
    },
    removeProductDelete(state) {
      state.productDelete = false;
    },
    setAdminUser(state, action) {
      state.AdminUser = action.payload;
    },
    setCategory(state, action) {
      state.categoryId = action.payload;
    },
    setMessageCount(state, action) {
      state.messageCount = action.payload;
    },
    setMessageCountList(state, action) {
      const valueToPush = action.payload;
      const exists = state.messageCountList.some((subArray) => subArray.includes(valueToPush));

      if (!exists) {
        state.messageCountList.push([valueToPush]);
      }
    },
    setMessageCountListEmpty(state) {
      state.messageCountList = [];
    },
    setLastPage(state, action) {
      state.lastPage = action.payload;
    },
    setChatHeaderData(state, action) {
      state.chatHeaderData = action.payload;
    },
    setUserNotifications(state, action) {
      state.userNotifications = action.payload;
    },
    setAdminNotifications(state, action) {
      state.adminNotifications = action.payload;
    },
    setAddUserNotifications(state, action) {
      state.userNotifications.push(action.payload);
    },
    setAddAdminNotifications(state, action) {
      state.adminNotifications.push(action.payload);
    },
    setIsChatProductActive(state, action) {
      state.isChatProductActive = action.payload;
    },
    triggerPageReload: (state) => {
      state.pageReload = Date.now();
    },
  },
});

export const {
  login,
  logout,
  open,
  close,
  add,
  remove,
  search,
  prodParams,
  openSnackBar,
  closeSnackBar,
  updateStateId,
  updateChatState,
  updateAdminLogin,
  fetchDirectConversations,
  addDirectMessage,
  fetchCurrentMessages,
  setCurrentConversation,
  updateProductDelete,
  openSidebar,
  closeSidebar,
  setAdminUser,
  setCategory,
  setMessageCount,
  setMessageCountList,
  setLastPage,
  setChatHeaderData,
  setMessageCountListEmpty,
  setUserNotifications,
  setAdminNotifications,
  setAddUserNotifications,
  setAddAdminNotifications,
  setIsChatProductActive,
  triggerPageReload,
} = userSlice.actions;

export const selectUser = (state) => state.auth.user;

export const selectModel = (state) => state.auth.isLoggingModel;

export const selectSidebar = (state) => state.auth.isMobileSidebarOpen;

export const selectWishListCount = (state) => state.auth.wishListCount;

export const selectMessageCount = (state) => state.auth.messageCount;

export const selectMessageCountList = (state) => state.auth.messageCountList;

export const SelectSearch = (state) => state.auth.searchKey;

export const selectProdParams = (state) => state.auth.prodParams;

export const SelectSnackBar = (state) => state.auth.snackbar;

export const SelectStateId = (state) => state.auth.stateId;

export const SelectChatState = (state) => state.auth.chatState;

export const selectAdminLoginState = (state) => state.auth.isAdminLogin;

export const selectConversationList = (state) => state.auth.conversationList;

export const selectCurrentMessages = (state) => state.auth.currentMessages;

export const selectCurrentConversation = (state) => state.auth.currentConversation;

export const selectProductDelete = (state) => state.auth.productDelete;

export const selectAdminUser = (state) => state.auth.AdminUser;

export const selectCategoryId = (state) => state.auth.categoryId;

export const selectLastPage = (state) => state.auth.lastPage;

export const selectChatHeaderData = (state) => state.auth.chatHeaderData;

export const selectUserNotifications = (state) => state.auth.userNotifications;

export const selectAdminNotifications = (state) => state.auth.adminNotifications;

export const selectIsChatProductActive = (state) => state.auth.isChatProductActive;

export const selectPageReload = (state) => state.auth.pageReload;

export default userSlice.reducer;


export function ChatHeaderData () {
  return async (dispatch, getState) => {
    const { chatState } = getState().auth;
    const data = {
      userId: chatState.recipientId,
      productId: chatState.productId,
    };

    await getChatHeader(data)
      .then((res) => {
        dispatch(setChatHeaderData(res.data.data[0]));
        dispatch(setIsChatProductActive(res.data.data[0].status));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export function SideBarAllChatList () {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    const data = {
      UserId: user.userId,
    };

    await getAllChats(data)
      .then((res) => {
        dispatch(
          fetchDirectConversations({
            conversationList: res.data.data,
          })
        );
      })
      .catch((err) => {
        console.log(err.response.data.data);
      });
  };
 
};


export function ChatOneToOneConversationData  (){
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    const { chatState } = getState().auth;
    const data = {
      SenderId: user.userId,
      ReceiverId: chatState.recipientId,
      ProductId: chatState.productId,
    };
    getChatHistory(data)
      .then((res) => {
        dispatch(fetchCurrentMessages({ messages: res.data.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getNotificationsForUsers  () {
  return async (dispatch, getState) => {
    const { user } = getState().auth;
    if (user) {
      const data = {
        userId: user?.userId,
        limit: 10,
      };
      await getAllNotificationforUser(data)
        .then((res) => {
          dispatch(setUserNotifications(res.data.data));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
};

export function getNotificationsForAdmin  () {
  return async (dispatch) => {
    const data = {
      limit: 10,
    };
    await getAllNotificationforAdmin(data)
      .then((res) => {
        dispatch(setAdminNotifications(res.data.data));
      })
      .catch((err) => {
        console.error(err);
      });
  };
};