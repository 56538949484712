const base = '/';
// const baseApi = 'http://localhost:5000/'
   const baseApi = 'https://api.get-ug.com/';
//  const baseApi = 'https://localhost:7020/';

 const baseApiUrl = `${baseApi}api/`;

export const routerPath = {
  home: base,
  baseChatUrl: baseApi,
  baseApi: baseApiUrl,
  product: `${base}/product/:id`,
  profile: `${base}/ProfilePage`,
  chat: `home`,
  authHubUrl: `${baseApi}auth`,
  favorite: `${base}/favorite`,
  myPost: `${base}/myPost`,
  search: `${base}/search/:query`,
  Google_OAuth_Client_Id_Dev: '396051440536-dnrv0119v4vp49lcejd70trb64g4c722.apps.googleusercontent.com',
  Google_OAuth_Client_Id_Prod: '109483441207-6fo1bjgvj9vfnqmn6ailj2i7003j1o7g.apps.googleusercontent.com',
  NODE_ENV: 'production',

  API: {
    userLogin: `${baseApiUrl}userlogin`,
    registerByMobileSendOtp: `${baseApiUrl}registermobilesendotp`,
    registerUserByMobile: `${baseApiUrl}registeruserbymobile`,
    registerUserByGoogle: `${baseApiUrl}registerbygoogle`,
    registerUserByEmail: `${baseApiUrl}registeruserbyemail`,
    registerUserByEmailVerifyOtp: `${baseApiUrl}registerbyemailverifyotp`,
    resendOtp: `${baseApiUrl}resendOtp`,
    forgotPasswordCheckUser: `${baseApiUrl}forgetPasswordCheckUser`,
    updatePassword: `${baseApiUrl}updatePassword`,
    fetchProductWhileLoad: `${baseApiUrl}fetchProductWhileLoad`,
    fetchProductSinglePageLoad: `${baseApiUrl}fetchProductSinglePageLoad`,
    addFavourite: `${baseApiUrl}addFavourite`,
    removeFavourite: `${baseApiUrl}removeFavourite`,
    getAllFavourites: `${baseApiUrl}getWishlisProducts`,
    recentlyViewedProducts: `${baseApiUrl}recentlyViewedProducts`,
    saveRecentViewProduct: `${baseApiUrl}saveRecentViewProduct`,
    getWishListCount: `${baseApiUrl}getWishListCount`,
    getHomePageGetCategory: `${baseApiUrl}homePageGetCategory`,
    getSearchProducts: `${baseApiUrl}getSearchProducts`,
    getRelatedProducts: `${baseApiUrl}getRelatedProducts`,
    updateUserProfile: `${baseApiUrl}updateuserprofile`,
    insertProfileImage: `${baseApiUrl}insertProfileImage`,
    getCategoryProductWhileLoad: `${baseApiUrl}getCategoryProductWhileLoad`,
    getCategoryProductByName: `${baseApiUrl}getCategoryProductByName`,
    getRecentPostProduct: `${baseApiUrl}getRecentPostProduct`,
    getStateList: `${baseApiUrl}getAllStates`,
    insertProduct: `${baseApiUrl}insertproduct`,
    insertProductImage: `${baseApiUrl}insertProductimage`,
    getAllChats: `${baseApiUrl}getAllChats`,
    getChatHistory: `${baseApiUrl}getChatHistory`,
    getChatHistorybyConvId: `${baseApiUrl}getChatHistorybyConvId`,
    getChatHeader: `${baseApiUrl}getChatHeader`,
    adminUserLogin: `${baseApiUrl}adminUserLogin`,
    getMyPostProduct: `${baseApiUrl}getMyPostProduct`,
    deleteproduct: `${baseApiUrl}deleteproduct`,
    toggleBlockUser:`${baseApiUrl}toggle-block`,
    fetchBlockedUsers:`${baseApiUrl}blocked-users`,
    getAllPublicUsers: `${baseApiUrl}getAllPublicUsers`,
    getAllCategoryForAdmin: `${baseApiUrl}getAllCategoryForAdmin`,
    getCategoryByIdForAdmin: `${baseApiUrl}getCategoryByIdForAdmin`,
    insertCategory: `${baseApiUrl}insertCategory`,
    updateCategory: `${baseApiUrl}updateCategory`,
    deleteCategoryByIdForAdmin: `${baseApiUrl}deleteCategoryByIdForAdmin`,
    deletePublicUsersById: `${baseApiUrl}deletePublicUsersById`,
    getAllProductForAdmin: `${baseApiUrl}getAllProductForAdmin`,
    deleteProductForAdmin: `${baseApiUrl}deleteProductForAdmin`,
    dashboardUserGraph: `${baseApiUrl}dashboardUserGraph`,
    getDashboardCount: `${baseApiUrl}getDashboardCount`,
    dashboardProductGraph: `${baseApiUrl}dashboardProductGraph`,
    verifyUserSendOtp: `${baseApiUrl}verifyUserSendOtp`,
    reviewUserById: `${baseApiUrl}reviewUserById`,
    dashboardProductCategoryGraph: `${baseApiUrl}dashboardProductCategoryGraph`,
    updateReadCount: `${baseApiUrl}updateReadCount`,
    getSuggestedProducts: `${baseApiUrl}getSuggestedProducts`,
    getProductById: `${baseApiUrl}getProductById`,
    updateProductById: `${baseApiUrl}updateProductById`,
    updateProductStatusById: `${baseApiUrl}updateProductStatusById`,
    getPromotionProductDetails: `${baseApiUrl}getPromotionProductDetails`,
    updatePromotionProductById: `${baseApiUrl}updatePromotionProductById`,
    promoteProductById: `${baseApiUrl}promoteProductById`,
    rejectPromotionProductById: `${baseApiUrl}rejectPromotionProductById`,
    checkProductBelongsToThisUser: `${baseApiUrl}checkProductBelongsToThisUser`,
    getAllNotificationforUser: `${baseApiUrl}getAllNotificationforUser`,
    getAllNotificationforAdmin: `${baseApiUrl}getAllNotificationforAdmin`,
    updatedReadValueFromAdmin: `${baseApiUrl}updatedReadValueFromAdmin`,
    updateReadValueFromUser: `${baseApiUrl}updatedReadValue`,
    getAllSliderForAdmin: `${baseApiUrl}getAllSliderForAdmin`,
    insertSliderFromAdmin: `${baseApiUrl}insertSlider`,
    updateSliderFromAdmin: `${baseApiUrl}updateSlider`,
    getAllSliderForUser: `${baseApiUrl}getAllSliderForUser`,
    insertEnquiryDetails: `${baseApiUrl}insertEnquiryDetails`,
    getAllEnquiryForAdmin: `${baseApiUrl}getAllEnquiryForAdmin`,
    saveChatFile: `${baseApiUrl}saveChatFile`,
    deleteChatFile: `${baseApiUrl}deleteChatFile`,
    saveAudioChatFile: `${baseApiUrl}saveAudioChatFile`,
  },
};
