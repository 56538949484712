import { httpRequest } from '../services/httpService';
import { AdminAxiosInstance } from '../providers/AdminAxiosProvider';
import { routerPath } from '../config/constants';

export const userLogin = (formData) => httpRequest.post(routerPath.API.userLogin, formData);

export const resendOtp = (formData) => httpRequest.post(routerPath.API.resendOtp, formData);

export const verifyUserSendOtp = (formData) => httpRequest.post(routerPath.API.verifyUserSendOtp, formData);

export const registerMobileSendOtp = (formData) => httpRequest.post(routerPath.API.registerByMobileSendOtp, formData);

export const registerUserByMobileCall = (formData) =>
  httpRequest.post(routerPath.API.registerUserByMobile.toString(), formData);

export const registerUserByGoogle = (formData) => httpRequest.post(routerPath.API.registerUserByGoogle, formData);

export const registerUserByEmail = (formData) => httpRequest.post(routerPath.API.registerUserByEmail, formData);

export const registerUserEmailVerifyOtp = (formData) =>
  httpRequest.post(routerPath.API.registerUserByEmailVerifyOtp, formData);

export const forgotPasswordCheckUser = (formData) => httpRequest.post(routerPath.API.forgotPasswordCheckUser, formData);
export const updatePassword = (formData) => httpRequest.post(routerPath.API.updatePassword, formData);

export const fetchProductWhileLoad = (formData) =>
  httpRequest.get(
    `${routerPath.API.fetchProductWhileLoad}?limit=${formData.LData}&UserId=${formData.UserId}&stateId=${formData.stateId}`
  );

export const getSuggestedProducts = (formData) =>
  httpRequest.get(`${routerPath.API.getSuggestedProducts}?UserId=${formData.UserId}`);

export const fetchProductSinglePageLoad = (formData) =>
  httpRequest.get(`${routerPath.API.fetchProductSinglePageLoad}?ProductId=${formData.ProductId}`);

export const addToFavorite = (formData) => httpRequest.post(routerPath.API.addFavourite, formData);

export const removeFromFavorite = (formData) => httpRequest.post(routerPath.API.removeFavourite, formData);

export const getAllFavourites = (formData) =>
  httpRequest.get(`${routerPath.API.getAllFavourites}?UserId=${formData.UserId}`);

export const getRecentlyViewedProducts = (formData) =>
  httpRequest.get(`${routerPath.API.recentlyViewedProducts}?UserId=${formData.UserId}`);

export const saveRecentViewProduct = (formData) => httpRequest.post(routerPath.API.saveRecentViewProduct, formData);

export const getWishListCount = (formData) =>
  httpRequest.get(`${routerPath.API.getWishListCount}?UserId=${formData.UserId}`);

export const homePageGetCategory = () => httpRequest.get(routerPath.API.getHomePageGetCategory);

export const getSearchProducts = (formData) => httpRequest.post(routerPath.API.getSearchProducts, formData);
export const getRelatedProducts = (formData) =>
  httpRequest.get(`${routerPath.API.getRelatedProducts}?categoryId=${formData.categoryId}&UserId=${formData.UserId}`);

export const updateUserProfile = (formData) => httpRequest.post(routerPath.API.updateUserProfile, formData);

export const insertProfileImage = (formData) => httpRequest.post(routerPath.API.insertProfileImage, formData);

export const getCategoryProductWhileLoad = (formData) =>
  httpRequest.get(
    `${routerPath.API.getCategoryProductWhileLoad}?limit=${formData.LData}&UserId=${formData.UserId}&stateId=${formData.stateId}`
  );

export const getCategoryProductByName = (formData) =>
  httpRequest.get(
    `${routerPath.API.getCategoryProductByName}?catId=${formData.catId}&UserId=${formData.UserId}&limit=${formData.LData}&stateId=${formData.stateId}`
  );

export const getRecentPostProduct = (formData) =>
  httpRequest.get(`${routerPath.API.getRecentPostProduct}?userId=${formData.UserId}`);

export const getStateList = () => httpRequest.get(routerPath.API.getStateList);

export const insertProduct = (formData) => httpRequest.post(routerPath.API.insertProduct, formData);

export const insertProductImage = (formData) => httpRequest.post(routerPath.API.insertProductImage, formData);

export const getAllChats = (formData) => httpRequest.get(`${routerPath.API.getAllChats}?UserId=${formData.UserId}`);

export const updateReadCount = (formData) =>
  httpRequest.post(
    `${routerPath.API.updateReadCount}?ProductId=${formData.productId}&SenderId=${formData.senderId}&ReceiverId=${formData.ReceiverId}`
  );

export const getChatHistory = (formData) =>
  httpRequest.get(
    `${routerPath.API.getChatHistory}?ProductId=${formData.ProductId}&SenderId=${formData.SenderId}&ReceiverId=${formData.ReceiverId}`
  );
export const getChatHistorybyConvId = (formData) =>
  httpRequest.get(
    `${routerPath.API.getChatHistorybyConvId}?ConversationId=${formData.ConversationId}&UserId=${formData.UserId}`
  );

export const adminUserLogin = (formData) => httpRequest.post(routerPath.API.adminUserLogin, formData);

export const getChatHeader = (formData) =>
  httpRequest.get(`${routerPath.API.getChatHeader}?UserId=${formData.userId}&ProductId=${formData.productId}`);

export const getMyPostProduct = (formData) =>
  httpRequest.get(`${routerPath.API.getMyPostProduct}?UserId=${formData.UserId}`);

export const deleteproduct = (formData) =>
  httpRequest.post(`${routerPath.API.deleteproduct}?productId=${formData.productId}`);

export const toggleBlockUser = (formData) => httpRequest.post(routerPath.API.toggleBlockUser, formData);

export const fetchBlockedUsers = (formData) =>
  httpRequest.get(routerPath.API.fetchBlockedUsers, {
    params: { userId: formData.userId },
  });

export const getAllPublicUsers = () => AdminAxiosInstance.get(routerPath.API.getAllPublicUsers);

export const getAllCategoryForAdmin = () => AdminAxiosInstance.get(routerPath.API.getAllCategoryForAdmin);

export const deleteCategoryByIdForAdmin = (formData) =>
  AdminAxiosInstance.get(`${routerPath.API.deleteCategoryByIdForAdmin}?categoryId=${formData.categoryId}`);

export const insertCategory = (formData) => AdminAxiosInstance.post(routerPath.API.insertCategory, formData);

export const updateCategory = (formData) => AdminAxiosInstance.post(routerPath.API.updateCategory, formData);

export const deletePublicUsersById = (formData) =>
  AdminAxiosInstance.post(`${routerPath.API.deletePublicUsersById}?userId=${formData.userId}`);

export const getAllProductForAdmin = () => AdminAxiosInstance.get(routerPath.API.getAllProductForAdmin);

export const deleteProductForAdmin = (formData) =>
  AdminAxiosInstance.post(`${routerPath.API.deleteProductForAdmin}?productId=${formData.productId}`);

export const dashboardUserGraph = (formData) => AdminAxiosInstance.post(routerPath.API.dashboardUserGraph, formData);

export const getDashboardCount = () => AdminAxiosInstance.get(routerPath.API.getDashboardCount);

export const dashboardProductCategoryGraph = () => AdminAxiosInstance.get(routerPath.API.dashboardProductCategoryGraph);

export const dashboardProductGraph = (formData) =>
  AdminAxiosInstance.post(routerPath.API.dashboardProductGraph, formData);

export const reviewUserById = (formData) => AdminAxiosInstance.post(routerPath.API.reviewUserById, formData);

export const getProductById = (formData) =>
  httpRequest.get(`${routerPath.API.getProductById}?productId=${formData.productId}`);

export const updateProductById = (formData) => httpRequest.post(routerPath.API.updateProductById, formData);

export const updateProductStatusById = (formData) =>
  httpRequest.post(
    `${routerPath.API.updateProductStatusById}?productId=${formData.productId}&statusId=${formData.statusId}`
  );

export const getPromotionProductDetails = () => AdminAxiosInstance.get(routerPath.API.getPromotionProductDetails);

export const updatePromotionProductById = (formData) =>
  AdminAxiosInstance.post(routerPath.API.updatePromotionProductById, formData);

export const promoteProductById = (formData) => httpRequest.post(routerPath.API.promoteProductById, formData);
export const rejectPromotionProductById = (formData) =>
  AdminAxiosInstance.post(routerPath.API.rejectPromotionProductById, formData);

export const checkProductBelongsToThisUser = (formData) =>
  httpRequest.post(routerPath.API.checkProductBelongsToThisUser, formData);

export const getAllNotificationforUser = (formData) =>
  httpRequest.get(`${routerPath.API.getAllNotificationforUser}?userId=${formData.userId}&limit=${formData.limit}`);

export const getAllNotificationforAdmin = (formData) =>
  AdminAxiosInstance.get(`${routerPath.API.getAllNotificationforAdmin}?limit=${formData.limit}`);

export const updateReadNotificationCountForAdmin = () =>
  AdminAxiosInstance.post(routerPath.API.updatedReadValueFromAdmin);

export const updateReadNotificationCountForUser = (formData) => {
  httpRequest.post(`${routerPath.API.updateReadValueFromUser}?userId=${formData.userId}`);
};

export const getAllSliderListForAdmin = () => AdminAxiosInstance.get(routerPath.API.getAllSliderForAdmin);

export const getAllSliderForUser = () => httpRequest.get(routerPath.API.getAllSliderForUser);

export const insertEnquiry = (formDate) => httpRequest.post(routerPath.API.insertEnquiryDetails, formDate);

export const getAllEnquiryListForAdmin = () => AdminAxiosInstance.get(routerPath.API.getAllEnquiryForAdmin);

export const deleteChatFile = (formData) =>
  httpRequest.post(`${routerPath.API.deleteChatFile}?fileName=${formData.fileName}`);
